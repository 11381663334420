import styled from 'styled-components';
import { Card } from 'react-bootstrap';

import { Theme } from '../../utils/theme';

export const StyledCard = styled(Card)`
  min-height: 480px;

  @media (min-width: ${Theme.Breakpoints.SM}) {
    min-height: 360px;
  }

  @media (min-width: ${Theme.Breakpoints.MD}) {
    min-height: 400px;
  }

  @media (min-width: ${Theme.Breakpoints.LG}) {
    min-height: 480px;
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: ${Theme.Breakpoints.SM}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${Theme.Breakpoints.MD}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
