import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import CalloutCard from '../components/cards/callout-card';
import Layout from '../components/layout';
import MerchantCard from '../components/cards/merchant-card';
import SEO from '../components/seo';
import SkeletonCard from '../components/cards/skeleton-card';
import { CardGrid } from '../components/cards/styles';

export type CardType = {
  id: number;
  name: string;
  avatar: string | null;
  taxon: {
    content: string;
    avatar: string;
  };
  location: string;
  flag: string;
  poster: string;
};

const index: FC = () => {
  const [cards, setCards] = useState<CardType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getRandomCards = () => {
    setLoading(true);
    axios
      .get(`${process.env.GATSBY_API_URL}/merchants/random`)
      .then(response => {
        const { list } = response.data;
        setCards(list);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRandomCards();
  }, []);

  return (
    <Layout>
      <SEO title="Manage product development through to production" article template={false} />
      <Container>
        <CardGrid>
          <CalloutCard refresh={getRandomCards} />
          {loading ? (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          ) : (
            <>
              {cards.map(card => (
                <MerchantCard key={card.id} card={card} />
              ))}
            </>
          )}
        </CardGrid>
      </Container>
    </Layout>
  );
};

export default index;
