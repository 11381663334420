import Flag from 'react-world-flags';
import styled from 'styled-components';
import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import _ from 'lodash';
import UserAvatar from 'react-user-avatar';

import { StyledCard } from '../styles';
import placeholder from '../../../../static/images/placeholder.png';
import { CardType } from '../../../pages';

const Taxon = styled.h5`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1rem;
`;

const Country = styled.h6`
  color: #6c757d;
  font-size: 0.9rem;
  font-weight: normal;
  margin: 0;
`;

const StyledCardHeader = styled(Card.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`;

const StyledCardFooter = styled(Card.Footer)`
  background-color: #fff;
`;

const StyledCardBody = styled(Card.Body)`
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  .UserAvatar {
    position: absolute;
    bottom: -30px;
    right: 15px;
  }
`;

const Meta = styled.div`
  margin-right: 0.5rem;
`;

export type CardProps = {
  card: CardType;
};

const MerchantCard: FC<CardProps> = ({ card }) => 
  <StyledCard>
    <StyledCardHeader>
      <Meta>
        <Taxon>{card.taxon.content}</Taxon>
        <Country>{card.location}</Country>
      </Meta>
      <Flag code={card.flag} height={22} />
    </StyledCardHeader>

    <StyledCardBody image={card.poster || card.taxon.avatar || placeholder}>
      <UserAvatar size={60} name={card.name} src={card.avatar} />
    </StyledCardBody>
    <StyledCardFooter>{card.name}</StyledCardFooter>
  </StyledCard>
;
export default MerchantCard;
