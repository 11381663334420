import React, { FC } from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { StyledCard } from '../styles';
import Skeleton from 'react-loading-skeleton';
import placeholder from '../../../../static/images/placeholder.png';

const StyledCardHeader = styled(Card.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`;

const StyledCardFooter = styled(Card.Footer)`
  background-color: #fff;
`;

const StyledCardBody = styled(Card.Body)`
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  & > span {
    position: absolute;
    bottom: -30px;
    right: 15px;
  }
`;

const Meta = styled.div`
  margin-right: 0.5rem;
`;

const SkeletonCard: FC = () => 
  <StyledCard>
    <StyledCardHeader>
      <Meta>
        <div>
          <Skeleton count={1} width={220} />
        </div>
        <div>
          <Skeleton count={1} width={150} />
        </div>
      </Meta>
      <Skeleton width={40} />
    </StyledCardHeader>
    <StyledCardBody image={placeholder}>
      <Skeleton circle={true} height={60} width={60} />
    </StyledCardBody>
    <StyledCardFooter>
      <Skeleton count={1} width={180} />
    </StyledCardFooter>
  </StyledCard>
;
export default SkeletonCard;
