import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { StyledCard } from '../styles';
import { Theme } from '../../../utils/theme';

type CalloutProps = {
  locale: string;
};

const Callout = styled.h1<CalloutProps>`
  font-weight: 600;
  margin-bottom: 15px;
  ${props =>
    props.locale === 'de' &&
    css`
      font-size: 32px;
    `}
`;

const SubLink = styled.div`
  a {
    color: ${Theme.Colors.TEXT};
    font-size: 1rem;

    &:hover {
      color: ${Theme.Colors.TEXT};
      text-decoration: underline;
    }
  }
`;

const RefreshButton = styled.button`
  background: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 5px 9px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StyledCardBody = styled(Card.Body)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type CalloutCardProps = {
  refresh: React.MouseEventHandler<HTMLElement>;
};

const CalloutCard: FC<CalloutCardProps> = ({ refresh }) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledCard>
      <StyledCardBody>
        <RefreshButton onClick={refresh}>
          <FontAwesomeIcon icon={faSync} width="16" />
        </RefreshButton>
        <Card.Text as="div">
          <Callout locale={i18n.language}>{t('page.home.callout')}</Callout>
          <SubLink dangerouslySetInnerHTML={{ __html: t('cms:what-is-makersflow') }} />
        </Card.Text>
      </StyledCardBody>
    </StyledCard>
  );
};
export default CalloutCard;
